import React from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"

import { getCurrentLangKey } from 'ptz-i18n';

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from '../components/Work/HeaderSection/HeaderSection'

const WorkPage = props => {
  /* store work sections */

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;
  
  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const aboutData = get(props, 'data.work.nodes[0]', [])
  const seoData = get(props, 'data.work.nodes[0].seo', [])

  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'nl',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.work.nodes[0].slug,
    slugEn: props.data.translatedUrl.nodes[0].slug,
  }

  return(
    <Layout 
      form={true} data={props.data.site} 
      location={props.location} 
      slugs={slugs}>
      <SEO {...data}/>
      <HeaderSection url={homeLink} data={aboutData} />
  </Layout>
  )
}

export default WorkPage

export const query = graphql`
{
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }
  translatedUrl:allCraftWorkWorkEntry(filter: {sectionHandle: {eq: "work"}, siteId: {eq: 1}}) {
    nodes{
      slug
    }
  }
  work:allCraftWorkWorkEntry(filter: {sectionHandle: {eq: "work"}, siteId: {eq: 2}}) {
    nodes {
      title
      slug
      workServiceImage{
        id
        url
      }
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`